.Logo {
  position: absolute;
  top: 20px;
  left: 20px;
  display: none;

  @media(min-width: 626px) {
    display: block;
  }
}
