.Copyright {
  padding: 15px;
  text-align: center;
  color: #dedede;

  &-link {
    color: #dedede !important;
    text-decoration: none !important;

    &:hover {
      color: white !important;
      text-decoration: underline !important;
    }
  }
}
