.TopBar {
  min-height: 80px;

  &-brand {
    padding: 18px 0;
    font-family: Tahoma;
  }

  &-brand.home {
    @media(min-width: 626px) {
      display: none;
    }
  }

  &-title {
    text-shadow: 0 0 6px #353535;
  }

  &-link {
    color: white;

    &:hover {
      color: white;
    }
  }

  &-like {
    padding: 10px 0;
    text-align: left;

    @media(min-width: 992px) {
      text-align: right;
    }
  }
}

#u_0_3 {
  color: white !important;
}
