.AdSenseLeft {
  padding-left: 10px;
  padding-right: 10px;
  /*height: 90px;*/
}

@media(min-width: 992px) {
  .AdSenseLeft {
    height: 600px;
    padding-right: inherit;
  }
}
