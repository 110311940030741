.progress {
    height: 34px;
    border: 1px solid $primaryColor;
}

.progress-bar {
    line-height: 34px;
    font-size: 18px;
    background-color: $primaryColor;
}

.progress-value {
    display: block;
    position: absolute;
    left: 50%;
    font-size: 18px;
    padding-top: 4px;
    color: white;
    margin-left: -40px;

    &.lessThan60 {
        color: #777;
    }
}
