.Board {
  background-image: url('../../../../assets/images/pancarta.png');
  text-align: center;
  position: relative;
  margin: 0 auto;
  margin-bottom: 30px;
  height: 244px;
  width: 345px;
  padding-top: 11px;

  @media(min-width: 626px) {
    margin-left: 39%;
  }
  @media(min-width: 992px) {
    margin-left: 49%;
    margin-top: 0;
  }

  &-year {
    color: $muteColor;
    font-size: large;
    color: rgba($muteColor, 0.8);
    font-weight: bold;
  }

  &-month {
    color: $muteColor;
    font-size: x-large;
    font-weight: bold;
    color: rgba($muteColor, 0.6);
  }

  &-day {
    color: rgba($muteColor, 0.3);
    position: absolute;
    width: 100%;
    font-size: 160px;
    top: 25px;
    font-weight: bold;
    pointer-events: none;
  }

  &-verseContainer {
    position: absolute;
    bottom: 8px;
    right: 25px;
  }

  &-contentContainer {
    position: absolute;
    top: 34px;
    bottom: 0;
    display: flex;
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    align-items: center;
  }

  &-content {
    font-size: 12px;
    text-align: justify;
    padding: 0 32px;
  }
}
