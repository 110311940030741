.Cta {
  background: url('../../../../assets/images/botones.png');
  position: relative;
  width: 332px;
  height: 100px;
  margin: 0 auto;

  @media(min-width: 626px) {
    margin-left: 39%;
  }
  @media(min-width: 960px) {
    margin-left: 49%;
  }

  &-share {
    position: absolute;
    top: 15px;
    left: 23px;
    -ms-transform: rotate(-7deg); /* IE 9 */
    -webkit-transform: rotate(-7deg); /* Chrome, Safari, Opera */
    transform: rotate(-7deg);
  }

  &-comment {
    position: absolute;
    right: 35px;
    top: 12px;
    -ms-transform: rotate(2.5deg); /* IE 9 */
    -webkit-transform: rotate(2.5deg); /* Chrome, Safari, Opera */
    transform: rotate(2.5deg);
  }

  &-link {
    color: white;
    font-size: 18px;
    text-shadow: 0 0 4px black;

    &:hover,
    &:visited {
      color: white;
      text-shadow: 0 0 10px black;
    }
  }
}
