.MainMenu {
  height: 34px;
  margin: 0 auto;
  background: url('../../assets/images/menu_bg.jpg') repeat-x left top;
}

ol#toc {
  height: 28px;
  list-style: none;
  margin: 0;
  padding: 6px 6px 0;
}

ol#toc a {
  color: #fff;
  display: block;
  float: left;
  height: 28px;
  text-decoration: none;
  text-align:center;
  padding: 0 10px;
}

ol#toc a:hover {
  background: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #294557;
}

ol#toc a:hover span {
  color: #294557;
  line-height:28px;
  padding:0;
  height:28px;
}

ol#toc li {
  float: left;
  margin: 0 1px 0 0;
}

ol#toc li.current a {
  background: #fff;
  color: #294557;
  text-align:center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

ol#toc li.current span {

}

ol#toc span {
  line-height:28px;
}
