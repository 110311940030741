.VerseListHeader {
  padding: 16px 0;

  @media(min-width: 992px) {
    padding-top: 8px;
  }

  &-title {
    font-size: 24px;
  }

  &-search {
    padding-top: 7px;

    @media(min-width: 768px) {
      text-align: right;
    }
  }
}
