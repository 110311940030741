.AdSenseCustomRight {
  width: 320px;
  height: 250px;
  margin: 0 auto;

  @media(min-width: 768px) {
    width: 120px;
    height: 600px;
  }

  &-image {
    display: block;
    border: 1px solid lightgray;
  }
}
