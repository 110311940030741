.Flower {
  background-image: url('../../../../assets/images/flor.png');
  position: absolute;
  left: 50%;
  margin-left: -90px;
  bottom: 0;
  width: 164px;
  height: 248px;

  @media(min-width: 626px) {
    left: 33px;
    bottom: 60px;
    margin-left: auto;
  }
  @media(min-width: 992px) {
    left: 100px;
    bottom: 60px;
    margin-left: auto;
  }
}
