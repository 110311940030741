.ShareButton {
  background-color: #d4781a;
  border: 1px solid #964d26;
  color: #fff;
  border-radius: 4px;
  padding: 4px 8px;
  text-shadow: 0 0 2px black;

  &:hover {
    color: #fff;
    text-shadow: 0 0 4px black;
  }
}
