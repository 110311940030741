.ReceiveButton {
  background-color: #5c9955;
  border: 1px solid #455d44;
  color: #fff;
  border-radius: 4px;
  padding: 4px 8px;
  text-shadow: 0 0 2px black;

  &:hover {
    color: #fff;
    text-shadow: 0 0 4px black;
  }
}
