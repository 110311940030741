body {
  background: #004b97;
  font-family: Tahoma, Geneva, sans-serif;
  margin: 0;
  padding: 0;
  color: #696969;
}

p {
  margin-bottom: 10px !important;
}

a {
  color: #3b5998;
  text-decoration: none;

  &:hover {
    color: darken(#3b5998, 5%);
    text-decoration: none;
  }
}

p, ul {
  margin-bottom: 20px;
  line-height: 1.6em;
}

/* HEADINGS
----------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {
  color: #3b5998;
  font-family: Tahoma, Geneva, sans-serif;
  margin: 0;
  padding: 0;
}

h5 {
  margin-bottom: 4px;
  font-weight: bold;
}

/* FORM LAYOUT ELEMENTS
----------------------------------------------------------*/

fieldset
{
  margin: 1em 0;
  padding: 1em;
  border: 1px solid #CCC;
}

fieldset p
{
  margin: 2px 12px 10px 10px;
}

legend
{
  font-size: 1.1em;
  font-weight: 600;
  padding: 2px 4px 8px 4px;
}

input[type="text"]
{
  /*width: 200px;*/
  border: 1px solid #CCC;
}

input[type="password"]
{
  /*width: 200px;*/
  border: 1px solid #CCC;
}

/* MISC
----------------------------------------------------------*/
.clear
{
  clear: both;
}

.error
{
  color:Red;
}

.footer .add{
  padding:7px 0 0 40px;
}
.contentversiculo{
/*  width:640px;*/
padding-left:13px;
padding-top:15px;
}
.contactface {
  margin-top:50px;
  margin-left:80px;
}
.content_versiculo{
  display:block;
  margin-left: 10px;
}
ul.contHVersiculo{
  list-style:none;
  margin:0;
  padding:0;
}
.contHVersiculo li em{
  float:left;
  width:65px;
  padding-right:10px;
}
.contHVersiculo li span{
  float:right;
  font-size:16px;
  color:#333;
  line-height:18px;
  width: 550px;
}
.contHVersiculo li span h3{
  font-size:30px;
  margin:0 0 6px;
  padding:0;
  color:#333333;
  line-height:31px;
}
.contHVersiculo li span h3 img{
  padding-left:20px;
}
.contHVersiculo li span p{
  margin-top:0;
}
.about h3{
  color:#333333;
  font-size:30px;
  line-height:31px;
  margin:0 0 0px;
  padding:0;
}

.content .contentversiculo .about
{
  width:5500px;
  background-color:#0C3;
}

.tweet_button
{
  float:left;
  margin-left:80px;
}

.fbshare_button
{
  float:left;
  margin-top:2px;
  margin-left:40px;
}

.fblike_button
{
  float:left;
  margin-left:10px;
}
#loading {
  text-align: center;
}

#comentarios{
  width:630px;
  height:600px;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
}
iframe.megusta{
  height: 95px;
  margin-top: -10px;
  width: 95px;
  overflow:hidden;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  visibility: visible;
  z-index: 1000;
}

.text-xs-center {
  text-align: center;

  @media(min-width: 760px) {
    text-align: left;
  }
}
