.Verse {
  margin-bottom: 10px;
  border: 1px solid $primaryColor;
  border-radius: 10px;
  overflow: hidden;

  &-header {
    background-color: #ebecf1;
    height: 35px;
    border-bottom: 1px solid $primaryColor;
    position: relative;
  }

  &-day {
    position: absolute;
    left: 10px;
    top: 2px;
    width: 30px;
    height: 30px;
    border-radius: 9999px;
    background-color: white;
    border: 1px solid $primaryColor;
    text-align: center;
    font-size: 19px;
  }

  &-month {
    position: absolute;
    left: 50px;
    padding-top: 2px;
    font-size: 20px;
  }

  &-year {
    position: absolute;
    right: 10px;
    padding-top: 2px;
    font-size: 20px;
  }

  &-body {
    background-color: #f6f7fb;
    padding: 10px 15px;
  }

  &-thumb {
    width: 74px;
    float: left;
    margin-right: 10px;
  }

  &-content {
    line-height: 1.2em;
    font-size: 12px;
  }

  &-contentTitle,
  &-contentText {
    margin-left: 80px;
  }
}

.Verse-body--big {
  background-color: white;

  .Verse-thumb {
    width: 120px;
  }

  .Verse-contentTitle {
    color: $bodyColor;
  }

  .Verse-contentParagraph {
    font-size: 16px;
  }

  .Verse-thumbImg {

  }
}
